import {
  DEFAULT_PERIOD_LENGTHS,
  DEFAULT_GAME_FLAGGING_CRITERIA,
} from "@/constants";

export const FIELDS_MAPPING = {
  title: "Season name",
  externalId: "External ID",
  "penaltySettings": "Penalty class",
  "playerOfTheGame/coupon": "Coupon",
};

const currentYear = new Date().getFullYear();
const defaultSeasonStartYear =
  new Date() >= new Date(`${currentYear}-08-01`) ? currentYear : currentYear - 1;
const defaultSeasonEndYear = defaultSeasonStartYear + 1;

const defaultSeasonStartDateValue = `${defaultSeasonStartYear}-08-16`;
const defaultSeasonEndDateValue = `${defaultSeasonEndYear}-08-15`;

export const DEFAULT_VALUES = {
  title: "",
  externalId: "",
  generalSettings: {
    periodLengths: DEFAULT_PERIOD_LENGTHS,
    gameCategories: [],
    signatures_required: "head_coach",
    shootout: false,
  },
  statsSettings: {
    assistValue: 1,
    goalValue: 1,
    playerStatsDisplay: "full_stats",
    pointSystem: {
      title: "2-point",
      gameValues: {
        regulation: {
          win: 0,
          tie: 0,
          loss: 0,
          shutout: 0,
        },
        overtime: {
          win: 0,
          tie: 0,
          loss: 0,
          shutout: 0,
        },
      },
      periodValues: {
        1: {
          win: 0,
          tie: 0,
          loss: 0,
        },
        2: {
          win: 0,
          tie: 0,
          loss: 0,
        },
        3: {
          win: 0,
          tie: 0,
          loss: 0,
        },
      },
    },
    fairPlayPoints: {
      isEnabled: false,
      maxPenaltyMinutes: 16,
      pointsValue: 1,
    },
    tieBreakers: [
      "points",
      "wins",
      "diff",
      "goal_quotient",
      "goal_quotient2",
      "goals_for",
      "goals_against",
      "games_played",
      "ties",
      "losses",
      "period_points",
      "shutouts",
      "periods_won",
      "overtime_shootout_wins",
      "overtime_shootout_losses",
      "quickest_goal",
      "penalty_minutes",
      "manual",
      "h2h_points",
      "h2h_wins",
      "reg_wins",
      "h2h_reg_wins",
      "h2h_diff",
      "h2h_quotient1",
      "h2h_periods_won",
      "fair_play_points",
      "h2h_fair_play_points",
      "points_percent",
    ],
  },
  penaltySettings: {
    penaltyCodes: [],
    penaltyDurations: [],
    penaltyLengths: [],
    penaltyClasses: [],
  },
  flaggingSettings: {
    criteria: DEFAULT_GAME_FLAGGING_CRITERIA,
    penalties: [],
  },
  playerOfTheGame: null,
  startDate: defaultSeasonStartDateValue,
  endDate: defaultSeasonEndDateValue,
  liveScoringMode: "public",
  leagueAppMode: "disabled"
};

export const CONSTRAINTS = {
  title: {
    presence: { allowEmpty: false, message: "^Season name can’t be blank" },
  },
  flaggingSettings: {
    gameFlaggingSettings: true,
  },
};
