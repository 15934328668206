import React, { useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import useDivisionTeamsList from "./hooks/useDivisionTeamsList";
import EmptyListMessage from "@/components/EmptyListMessage";
import TotalCount from "@/components/TotalCount";
import AbilityButton from "@/components/AbilityButton";
import { Can, canCheck } from "@/components/Ability";
import PageLoader from "@/components/PageLoader";
import ActionsDropdown from "./ActionsDropdown";
import { useTeamsListTotalCount, useExport, useInvitationsExport } from "./hooks";
import { LockImg } from "@/components-16.8/LockImg";
import Spinner from "@/components/Spinner";
import { PaginationBar } from "@/components-16.8/PaginationBar";
import { getCustomHeader } from "./getCustomSortHeader";
import "./_teamsList.scss"

function TeamsList({
  seasonId,
  divisionId,
  teams,
  displayDivision,
  csvRef,
  title,
  lockingTeams,
  triggerLockTeam,
  pageNumber,
  pageSize,
  sort
}) {
  
  const placeholder = "https://imagedelivery.net/ErrQpIaCOWR-Tz51PhN1zA/e4d8b129-b545-4ed1-c2a5-2f7787e5c300"
  const history = useHistory();
  const { triggerExport } = useExport({ seasonId, divisionId, title });
  const { triggerInvitationExport } = useInvitationsExport({ seasonId, divisionId, title });
  const [gridApi, setGridApi] = useState(null);
  const totalCount = useTeamsListTotalCount();
  const { isLoading, isLoaded } = useDivisionTeamsList();
  const isMobile = window.innerWidth < 780 ? true : false;
  const [ locking, setLocking ] = useState(false);

  const onGridReady = params => {
    setGridApi(params.api);
  };

  useImperativeHandle(csvRef, () => ({
    ExportToCSV: triggerExport,
    ExportInvitationsToCSV: triggerInvitationExport
  }));

  const setUrlParam = (label, val) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(label, val);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const setUrlPageParam = page => {
    setUrlParam("page", page);
  };

  const setSort = sort => {
    setUrlParam("sort", sort);
  };

  const canDelete = canCheck({ I: "delete", this: "teams" });
  const canUpdate = canCheck({ I: "update", this: "teams" });
  const ignoreLock = canCheck({ I: "ignore", this: { type: "teamLocks" } });

  const newTeamUrl = divisionId
    ? `/seasons/${seasonId}/divisions/${divisionId}/teams/new`
    : `/seasons/${seasonId}/teams/new`;

  const paginationRows = {
    currentLow: (pageNumber - 1) * pageSize + 1,
    currentHigh: Math.min(pageNumber * pageSize, totalCount),
    total: totalCount
  };

  const paginationPages = {
    current: pageNumber,
    total: Math.ceil(totalCount / pageSize)
  };

  if (isLoaded && teams.length < 1) {
    return (
      <EmptyListMessage>
        There Are No Teams.
        <br />
        <br />
        {newTeamUrl && (
          <AbilityButton
            subject={{
              type: "teams",
              ...(divisionId ? { divisionId } : { seasonId })
            }}
            action="create"
            tag={Link}
            size="sm"
            color="success"
            outline
            to={newTeamUrl}
          >
            Create a new one
          </AbilityButton>
        )}
      </EmptyListMessage>
    );
  }

  return (
    <PageLoader isLoading={isLoading} isLoaded={isLoaded}>
      <div className="d-flex flex-row justify-content-between">
        <TotalCount plural="teams" singular="team" totalCount={totalCount} />
        {locking && <Spinner theme="loader" color="yellow" size="m" />}
      </div>
      <div className="ag-theme-alpine ag-gs-theme">
        <AgGridReact
          defaultColDef={{
            resizable: true,
            wrapText: true,
            autoHeight: true,
          }}
          pagination={false}
          rowData={teams}
          onGridReady={onGridReady}
          domLayout={"autoHeight"}
          suppressCellSelection={true}
          rowHeight={44}
          enableCellTextSelection={true}
          getRowNodeId={(data) => data.id}
        >
          <AgGridColumn
            headerName="Logo"
            field="logo"
            lockPosition={isMobile}
            minWidth="56"
            width="56"
            sortable={false}
            //headerComponent={getCustomHeader(setSort, sort, "title")}
            cellRenderer={(params) => `<img src='${params.data.logoUrl || placeholder}/32' width='32' height='32' />`}
          />
          <AgGridColumn
            headerName="Title"
            field="title"
            lockPosition={isMobile}
            minWidth="175"
            flex="1"
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "title")}
            cellRendererFramework={params => {
              const { season, id } = params.data;
              return (
                <Link to={`/seasons/${season.id}/teams/${id}/roster/players`}>{params.value}</Link>
              );
            }}
          />
          <AgGridColumn
            headerName="Division"
            field="division.title"
            lockPosition={isMobile}
            minWidth="200"
            flex="1"
            hide={!displayDivision}
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "division")}
            cellRenderer={(params) => {
              const divisionTitle = params.data.division.title;
              return `<p>${divisionTitle}</p>`;
            }}
          />
          <AgGridColumn
            headerName="Id"
            field="id"
            lockPosition={isMobile}
            minWidth="70"
            width="70"
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "id")}
            cellRenderer={(params) => {
              return `<p>${params.data.id}</p>`;
            }}
          />
          <AgGridColumn
            headerName="Players"
            field="numPlayers"
            lockPosition={isMobile}
            minWidth="80"
            width="80"
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "players")}
            cellRendererFramework={(params) => {
              const { data } = params.data;
              return (
                <>
                  <span>{params.data.numPlayers}</span>
                  {data.rosterLocked && <LockImg height="12" className="d-inline ml-1" />}
                </>
              );
            }}
          />
          <AgGridColumn
            headerName="Coaches"
            field="numCoaches"
            lockPosition={isMobile}
            minWidth="80"
            width="80"
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "coaches")}
            cellRendererFramework={(params) => {
              const { data } = params.data;
              return (
                <>
                  <span>{params.data.numCoaches}</span>
                  {data.rosterLocked && <LockImg height="12" className="d-inline ml-1" />}
                </>
              );
            }}
          />
          <AgGridColumn
            headerName="Invitation Code"
            field="invitations"
            minWidth="150"
            flex="1"
            lockPosition={isMobile}
            hide={!canCheck({ I: "read", an: "invitations" })}
            sortable={true}
            headerComponent={getCustomHeader(setSort, sort, "invitation")}
            cellRendererFramework={(params) => {
              const invitations = params.data.invitations;
              return (
                <Can I="read" an="invitations">
                  {invitations.length > 0
                    ? invitations.map((invitation, index) => (
                        <div key={index}>
                          <code>{invitation.code}</code>
                        </div>
                      ))
                    : ""}
                </Can>
              );
            }}
          />

          <AgGridColumn
            headerName=""
            field=""
            lockPosition={isMobile}
            minWidth="120"
            flex="1"
            type="rightAligned"
            sortable={false}
            hide={!canDelete && !canUpdate}
            cellRendererFramework={(params) => {
              const { id, title, data } = params.data;
              return (
                <ActionsDropdown
                  id={id}
                  seasonId={params.data.season.id}
                  divisionId={params.data.division.id}
                  title={title}
                  setLocking={setLocking}
                  locked={!!data.rosterLocked}
                  ignoreLock={ignoreLock}
                  displayDivision={displayDivision}
                />
              );
            }}
          />
        </AgGridReact>
        <PaginationBar
          rows={paginationRows}
          pages={paginationPages}
          nav={{
            onFirst: () => setUrlPageParam(1),
            onBack: () => setUrlPageParam(Math.max(pageNumber - 1, 1)),
            onForward: () => setUrlPageParam(Math.min(Number(pageNumber) + 1, paginationPages.total)),
            onLast: () => setUrlPageParam(paginationPages.total),
          }}
        />
      </div>
    </PageLoader>
  );
}

TeamsList.propTypes = {
  seasonId: PropTypes.string.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  divisionId: PropTypes.string,
  displayDivision: PropTypes.bool,
  lockingTeams: PropTypes.arrayOf(PropTypes.string),
  triggerLockTeam: PropTypes.func
};

TeamsList.defaultProps = {
  displayDivision: false,
  lockingTeams: [],
  triggerLockTeam: () => {}
};

export default TeamsList;
