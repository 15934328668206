import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class Signature extends PureComponent {
  static propTypes = {
    signedBy: PropTypes.string,
    signature: PropTypes.string
  };

  static defaultProps = {
    signedBy: "N/A"
  };

  render() {
    const { signedBy, signature } = this.props;
    console.log(signature);

    var scoringAppSignature = false;
    var svgString = signature

    function extractSVGPath(svgString) {
      const match = svgString.match(/<path[^>]*d="M([^"]*)"/);
      console.log("match", match)
      return match ? match[1] : '';
    }

    if (signature.startsWith("<svg width")) {
      console.log("signature", signature);
      scoringAppSignature = true;
      svgString = "M" + extractSVGPath(signature);
      console.log("svgString", svgString);
    }

    return (
      <tr>
        <td classsignedBy="text-uppercase">{signedBy}</td>
        <td>
          <svg height="30" viewBox="0 0 500 300" xmlns="http://www.w3.org/2000/svg">
            <path d={svgString} stroke="black" fill="none" width="3" />
          </svg>
        </td>
      </tr>
    );
  }
}

export default Signature;
