import React, { useState } from "react";

import { Table, Popover, PopoverBody } from "reactstrap";

import EmptyListMessage from "@/components/EmptyListMessage";
import PageLoader from "@/components/PageLoader";
import TotalCount from "@/components/TotalCount";
import { Icon } from "@/components/GameFlagsList/Icon";

import Game from "./Game";

import useCompletedGamesList from "./hooks/useCompletedGamesList";
import useCompletedGamesListPagination from "./hooks/useCompletedGamesListPagination";

import "./List.scss";
import { getColors } from "../GameFlagsList/helpers";

function List() {
  const games = useCompletedGamesList();
  const [flagsPopoverOpen, setFlagsPopoverOpen] = useState(false);

  const { isListLoaded, isListLoading, totalCount, filteredCount, isFilterDirty } = useCompletedGamesListPagination();

  const flagColors = getColors();

  if (isListLoaded && !isListLoading && games.length === 0) {
    return (
      <EmptyListMessage>
        {isFilterDirty
          ? "We couldn't find any games matching your criteria. Try adjusting your search terms or reducing your filter selections."
          : "No games have been played so far."}
      </EmptyListMessage>
    );
  }

  const openFlagsPopover = () => {
    setFlagsPopoverOpen(true);
  }

  const closeFlagsPopover = () => {
    setFlagsPopoverOpen(false);
  }

  return (
    <PageLoader isLoaded={isListLoaded} isLoading={isListLoading}>
      <div className="games-page__list">
        <TotalCount plural="games" singular="game" totalCount={totalCount} filteredCount={filteredCount} />
        <Table className="games-page__table" striped borderless>
          <thead>
            <tr>
              <th>Visiting team</th>
              <th className="score">&nbsp;</th>
              <th>Home team</th>
              <th>Divisions</th>
              <th>Date</th>
              <th>Number</th>
              <th>Location</th>
              <th>Notes</th>
              <th>Viewed</th>
              <th
                className="games-page__flags-header"
                onMouseEnter={() => openFlagsPopover()}
                onMouseLeave={() => closeFlagsPopover()}
              ><span id="flags-header-text">Flags &#9432;</span></th>
              <Popover className="" placement="top" isOpen={flagsPopoverOpen} target="flags-header-text">
                <PopoverBody className="flags-popover">
                  <div className="legend-item"><div className="legend-icon-container"><Icon fill={flagColors["red"]} /></div> Penalty, Note by Referee, Game was Unlocked</div>
                  <div className="legend-item"><div className="legend-icon-container"><Icon fill={flagColors["supernova"]} /></div> Suspension Served</div>
                  <div className="legend-item"><div className="legend-icon-container"><Icon fill={flagColors["blue"]} /></div> Player Added at Game Time</div>
                  <div className="legend-item"><div className="legend-icon-container"><Icon fill={flagColors["green"]} /></div> Timeout, Injury Timeout, General Note by Scorekeeper</div>
                </PopoverBody>
              </Popover>
            </tr>
          </thead>
          <tbody>
            {games.map(game => (
              <Game key={game.id} {...game} />
            ))}
          </tbody>
        </Table>
      </div>
    </PageLoader>
  );
}

export default List;
