import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

import useScrollTo from "@/hooks/useScrollTo";
import ContentSection from "@/components/ContentSection";
import PlayersRoster from "./components/PlayersLineup";
import SuspendedMembersList from "./components/SuspendedMembersList";
import ScoringSheet from "./components/ScoringSheet";
import PenaltiesSheet from "./components/PenaltiesSheet";
import CoachesList from "./components/CoachesList";
import GoalieStats from "./components/GoalieStats";
import LineupSignature from "./components/LineupSignature";

class TeamsLayout extends PureComponent {
  static propTypes = {
    visitor: PropTypes.shape({
      title: PropTypes.string.isRequired,
      players: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      injuredPlayers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      suspendedMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      goals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      totalPenaltyMinutes: PropTypes.number.isRequired,
      penalties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      coaches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      goalieStats: PropTypes.shape({}).isRequired,
    }).isRequired,
    home: PropTypes.shape({
      title: PropTypes.string.isRequired,
      players: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      injuredPlayers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      suspendedMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      goals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      totalPenaltyMinutes: PropTypes.number.isRequired,
      penalties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      coaches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      goalieStats: PropTypes.shape({}).isRequired,
    }).isRequired,
    sport: PropTypes.string.isRequired,
    rawPeriods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  render() {
    const { visitor, home, sport, rawPeriods } = this.props;

    console.log(visitor, home)

    const { Element: PenaltyScrollTarget } = useScrollTo("penalties");

    return (
      <React.Fragment>
        <ContentSection title={`Lineups`} hSize="h3" className="gamesheet__rosters">
          <Row>
            <Col md="6">
              <PlayersRoster
                caption={`Visitor - ${visitor.title}`}
                players={visitor.players}
                injuredPlayers={visitor.injuredPlayers}
              />
              <SuspendedMembersList caption={`Suspended Players/Coaches`} members={visitor.suspendedMembers} />
              { Object.keys(visitor.lineupSignature).length > 0 && (
                <LineupSignature caption="Lineup Published By:" signature={visitor.lineupSignature} />
              )}
            </Col>
            <Col md="6">
              <PlayersRoster
                caption={`Home - ${home.title}`}
                players={home.players}
                injuredPlayers={home.injuredPlayers}
              />
              <SuspendedMembersList caption={`Suspended Players/Coaches`} members={home.suspendedMembers} />
              { Object.keys(home.lineupSignature).length > 0 && ( 
                <LineupSignature caption="Lineup Published By:" signature={home.lineupSignature} />
              )}
            </Col>
          </Row>
        </ContentSection>
        <ContentSection title={`Coaches`} hSize="h3" className="gamesheet__coaches">
          <Row>
            <Col md="6">
              <CoachesList caption={`Visitor - ${visitor.title}`} coaches={visitor.coaches} />
            </Col>
            <Col md="6">
              <CoachesList caption={`Home - ${home.title}`} coaches={home.coaches} />
            </Col>
          </Row>
        </ContentSection>
        <ContentSection title={`Scoring`} hSize="h3" className="gamesheet__scoring">
          <Row>
            <Col md="6">
              <ScoringSheet caption={`Visitor - ${visitor.title}`} goals={visitor.goals} sport={sport} rawPeriods={rawPeriods} />
            </Col>
            <Col md="6">
              <ScoringSheet caption={`Home - ${home.title}`} goals={home.goals} sport={sport} rawPeriods={rawPeriods} />
            </Col>
          </Row>
        </ContentSection>
        <ContentSection title={`Shots by Goalie`} hSize="h3" className="gamesheet__scoring">
          <Row>
            <Col md="6">
              <GoalieStats caption={`Visitor - ${visitor.title}`} stats={visitor.goalieStats} sport={sport} />
            </Col>
            <Col md="6">
              <GoalieStats caption={`Home - ${home.title}`} stats={home.goalieStats} sport={sport} />
            </Col>
          </Row>
        </ContentSection>
        <PenaltyScrollTarget>
          <ContentSection title={`${sport === 'soccer' ? "Infractions" : "Penalties"}`} hSize="h3" className="gamesheet__penalties ignore-last">
            <Row>
              <Col md="6">
                <PenaltiesSheet
                  caption={`Visitor - ${visitor.title}`}
                  penalties={visitor.penalties}
                  totalMinutes={visitor.totalPenaltyMinutes}
                  sport={sport}
                  rawPeriods={rawPeriods}
                />
              </Col>
              <Col md="6">
                <PenaltiesSheet
                  caption={`Home - ${home.title}`}
                  penalties={home.penalties}
                  totalMinutes={home.totalPenaltyMinutes}
                  sport={sport}
                  rawPeriods={rawPeriods}
                />
              </Col>
            </Row>
          </ContentSection>
        </PenaltyScrollTarget>
      </React.Fragment>
    );
  }
}

export default TeamsLayout;
